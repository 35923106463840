import React, { useState, useEffect } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "../../assets/css/productList.css";
import _ from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton';
import isEmpty from "is-empty";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';



const UpdateBalance = () => {
  const token = localStorage.getItem("token");
  // const decode = jwt_decode(token);
  const [csvData, setCsvData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [user, setUser] = useState('');



  useEffect(() => {
    // getUser();
  }, []);
	
    //Add Product from CSV File
  const updateTxn = (e) => {
    e.preventDefault();
    
    const form = document.getElementById("txnTable")
    const scvdata = new FormData(form);
    scvdata.append('user', user)
    console.log(user)
    // return false;
    axios
      .post('/itadmin/updateTxn', scvdata)
      .then((res) => {
        console.log(res.data.error);
		// if (res.data.includes('success')) {
		if (res.data.error==0) {
          NotificationManager.success('Balance updated Successfully');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.Response.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log('string, results')
    console.log(string, results)
    getUser(string)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    // console.log('selectitem')
    console.log(item)
    setUser(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnClear = () => {
    console.log("Cleared");
  };

  const formatResult = (item) => {
    console.log('item');
    console.log(item);
    return (
      <div className="result-wrapper">
        {/* <span className="result-span">id: {item.id}</span> */}
        <span className="result-span">email: {item.name}</span>
      </div>
    );
  };

  const getUser = async (searchUser) => {
    // e.preventDefault();
    
    // console.log(searchLocation)
    let obj = {
      'searchUser':searchUser
    }
    
    await axios.post('/itadmin/getUser', obj).then((response) => {
        if(response.data.error==1){
          setUser(searchUser)
        }else{
          console.log(response.data.message)
          setSearchUser(response.data.message)
        }
      
        
    });
        
  };

  const getUser1 = (e) => {
    
    axios
      .get('/itadmin/getUser')
      .then((res) => {
        console.log(res.data.error);
        if (res.data.error==0) {
          setAllUsers(res.data.message)
        }else{
          res.data.error
            ? NotificationManager.error(res.data.Response.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
        
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

  const items = [
    {
      id: 0,
      name: 'Cobol'
    },
    {
      id: 1,
      name: 'JavaScript'
    },
    {
      id: 2,
      name: 'Basic'
    },
    {
      id: 3,
      name: 'PHP'
    },
    {
      id: 4,
      name: 'Java'
    }
  ]

return (
    <div className="mainBox">
      <br />
      <div className="container-fluid">
        <div class="card" style={{"padding": "1em"}}>
          <form onSubmit={updateTxn} id="txnTable" name="txnTable" style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
            <div class="form-group" style={{"width": "inherit"}}>
              <div className="card" style={{padding: "1em"}}>
                <div className="row">
                  <div className="col-md-3"><label htmlFor='user'>User</label></div>
                  <div className="col-md-6">
                    {/* <select className='form-control' name="user" id="user" >
                      <option value="">Select</option>
                      {!isEmpty(allUsers) ? (
                        allUsers.map((inw) => {
                          return (
                            <option value={inw._id}>{inw.email}</option>
                          )
                        })
                      ) : (
                        <></>
                      )}
                  
                    </select> */}
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <div className="my-custom-wrapper">
                        <ReactSearchAutocomplete
                          items={searchUser}
                          onSearch={handleOnSearch}
                          onHover={handleOnHover}
                          onSelect={handleOnSelect}
                          onFocus={handleOnFocus}
                          onClear={handleOnClear}
                          styling={{ zIndex: 1 }}
                          formatResult={formatResult}
                          autoFocus
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3"><label htmlFor='txn_id'>Txn Id</label></div>
                  <div className="col-md-6">
                    <input
                          type='text'
                          className='form-control'
                          name='txn_id'
                          id="txn_id"
                        />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3"><label htmlFor='amt'>Amount</label></div>
                  <div className="col-md-6">
                    <input
                          type='text'
                          className='form-control'
                          name='amt'
                          id="amt"
                        />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3"><label htmlFor='method'>Method</label></div>
                  <div className="col-md-6">
                    <select className='form-control' name="method" id="method">
                        <option value="">Select</option>
                        <option value="1">Cash</option>
                        <option value="2">Bank Transfer</option>
                        <option value="3">System Admin</option>
                      </select>
                  </div>
                </div>

                <div className='row' style={{margin: "0 0 0 10px"}}>
                  <div className="col-md-2">
                    <CustomButton fill type='submit'>
                      Submit
                    </CustomButton>
                  </div>
                  
                </div>
                
              </div>
            </div>
          </form>
        </div>
      </div>

      
    </div>
  );
};

export default UpdateBalance;
