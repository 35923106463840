import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import MultipleBox from '../../components/MultipleBox';
import "react-step-progress-bar/styles.css";
import "../../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [awb, setAwb] = useState('');
  const [sku, setSku] = useState('');
  const [quantity, setQuantity] = useState('');
  const [qcaction, setQcaction] = useState('');
  const [claim, setClaim] = useState('');
  const [remark, setRemark] = useState('');
  const [scantype, setScantype] = useState('');
  const [accountname, setAccountname] = useState('');
  const [saleableQuantity, setSaleableQuantity] = useState(0);
  const [nonSaleableQuantity, setNonSaleableQuantity] = useState(0);
  const [damageQuantity, setDamageQuantity] = useState(0);
  const [skuLength, setSkuLength] = useState(0);
  
  const [multidimensionalArray, setMultidimensionalArray] = useState([]);
  const [inputData, setInputData] = useState([]);
  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    
  }, []);

  const onOptionChange = e => {
    setClaim(e.target.value)
  }
  

  const scanUpdate = async (e) => {
    // e.preventDefault();
    
    const formData = new FormData();
    // console.log(formData);

    formData.append('awb_scan', awb);
    formData.append('sku', sku);
    formData.append('quantity', quantity);
    formData.append('qc_action', qcaction);
    formData.append('saleableQty', saleableQuantity);
    formData.append('nonsaleableQty', nonSaleableQuantity);
    formData.append('damageQty', damageQuantity);
    formData.append('claim', claim);
    formData.append('remark', remark);
    formData.append('customer_name', accountname);
    formData.append('scan_type', scantype);

    // console.log(saleableQuantity)
    // console.log(nonSaleableQuantity)
    // console.log(damageQuantity)

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))==0){
      NotificationManager.error("Quantity should be enter");
      return false;
    }

    if((parseInt(saleableQuantity)+parseInt(nonSaleableQuantity)+parseInt(damageQuantity))>quantity){
      // console.log(saleableQuantity+nonSaleableQuantity+damageQuantity)
      NotificationManager.error("Entered quantity should not be more than Return quantity");
      return false;
    }
    
    await axios.post('/wms/scan', formData).then((response) => {
        
        if(response.data.error === 0){
          setAwb('');
          setSku('');
          setQuantity('');
          setQcaction('');
          setSaleableQuantity('');
          setNonSaleableQuantity('');
          setDamageQuantity('');
          setClaim('');
          setRemark('');
          setAccountname('');
          
          NotificationManager.success(response.data.response);
        }else if(response.data.error === 2){
          NotificationManager.warning(response.data.response);
        }
        else{
          setAwb('');
          setSku('');
          setQuantity('');
          setQcaction('');
          setSaleableQuantity('');
          setNonSaleableQuantity('');
          setDamageQuantity('');
          setClaim('');
          setRemark('');
          setAccountname('');  
          
          NotificationManager.success(response.data.response);
        }
        
    });
        
  };

  // let multidimensionalArray = [];
  const get_qty = async (e) => {
    // e.preventDefault();

    const awbdata = new FormData()
    awbdata.append('awb', awb)

    try {
    
    await axios.post('/wms/get_qty', awbdata).then((response) => {
      if(response.data.error==1){

      } else{
        
        if(response.data.length>0){
          // console.log(response.data)
          setSkuLength(response.data.length)

          // multidimensionalArray = response.data
          // setMultidimensionalArray([
          //   { sku: 'Item1', qty: 'Value1' },
          //   { sku: 'Item2', qty: 'Value2' },
          //   { sku: 'Item3', qty: 'Value3' },
          // ]);

          setMultidimensionalArray(response.data);

          setScantype(response.data[0].scan);
          
          // setSku(response.data[0].sku);
          // setQuantity(response.data[0].qty);
          setAccountname(response.data[0].account_name);

        }

        
        // setScantype(response.data[1].scan);

        // setSku(response.data[0].sku);
        // setQuantity(response.data[0].qty);
        // setAccountname(response.data[0].account_name);
      }
        
    });
  } catch (error) {
    console.error("Error fetching SKU data", error);
  }
        
  };

  const handleInputChange = (newInputValues, index) => {
    const updatedInputData = [...inputData];
    updatedInputData[index] = newInputValues;
    // setInputData(updatedInputData);
    setInputData(newInputValues);
    // console.log("Updated Input Data from Child:", newInputValues);
    // console.log("Updated Input Data from Child2:", updatedInputData);
  };

  const handleUpdate = async() => {
    
     if(inputData.length==0){
        alert("Please fill at least one input field before updating.");
        return;
     }else if(inputData.length<multidimensionalArray.length){
        alert("Entered quantity should not be less than Return quantity.");
        return;
     }

    // console.log("Data to be updated:", inputData);
  
    let error = 0;
    let error1 = 0;
    let final = [];
    multidimensionalArray.forEach((item, index) => {
      // console.log(inputData[index])
      // console.log(item.qty)
      let givanQty = 0;
      // let skuQty = item.qty;
      if(inputData[index].saleableQuantity){
        givanQty += parseInt(inputData[index].saleableQuantity)
      }

      if(inputData[index].nonSaleableQuantity){
        givanQty += parseInt(inputData[index].nonSaleableQuantity)
      }

      if(inputData[index].damageQuantity){
        givanQty += parseInt(inputData[index].damageQuantity)
      }

      
      if(givanQty>item.qty){
        
        // console.log(givanQty)
        error++;
      }

      if(givanQty==0 || givanQty<item.qty){
        // console.log(givanQty)
        error1++;
      }

      let obj = {
        'sku':item.sku,
        'saleableQuantity' : inputData[index].saleableQuantity?parseInt(inputData[index].saleableQuantity):0,
        'nonSaleableQuantity' : inputData[index].nonSaleableQuantity?parseInt(inputData[index].nonSaleableQuantity):0,
        'damageQuantity' : inputData[index].damageQuantity?parseInt(inputData[index].damageQuantity):0,
        'qcAction':inputData[index].qcAction
      }
      
      final.push(obj)

      // console.log(obj)
    })

    if(error>0){
      // console.log('yes')
      alert("Entered quantity should not be more than Return quantity.");
        return;
    }

    if(error1>0){
      // console.log('yes')
      alert("Entered quantity should not be less than Return quantity.");
        return;
    }

    console.log(final)

    const formData = new FormData();

    // final.forEach((item, index) => {
    //   // console.log('item')
    //   formData.append('arrayData', JSON.stringify(item));
    // });
    
    const arrayData = final.map(item => item); // Keep the items as they are

    // Ensure the result is an array (no need to stringify twice)
    const dataToSend = arrayData.length === 1 ? `[${JSON.stringify(arrayData[0])}]` : JSON.stringify(arrayData);

    // Append to formData
    formData.append('arrayData', dataToSend);
    

    // return false;
    formData.append('awb_scan', awb);
    // formData.append('sku', sku);
    // formData.append('quantity', quantity);
    // formData.append('qc_action', qcaction);
    // formData.append('saleableQty', saleableQuantity);
    // formData.append('nonsaleableQty', nonSaleableQuantity);
    // formData.append('damageQty', damageQuantity);
    formData.append('claim', claim);
    formData.append('remark', remark);
    formData.append('customer_name', accountname);
    formData.append('scan_type', scantype);
    // formData.append('final', final);

    await axios.post('/wms/scan', formData).then((response) => {
        
      if(response.data.error === 0){
        setAwb('');
        setSku('');
        setQuantity('');
        setQcaction('');
        setSaleableQuantity('');
        setNonSaleableQuantity('');
        setDamageQuantity('');
        setClaim('');
        setRemark('');
        setAccountname('');

        setMultidimensionalArray([])
        setInputData([])
        
        NotificationManager.success(response.data.response);
      }else if(response.data.error === 2){
        NotificationManager.warning(response.data.response);
      }
      else{
        setAwb('');
        setSku('');
        setQuantity('');
        setQcaction('');
        setSaleableQuantity('');
        setNonSaleableQuantity('');
        setDamageQuantity('');
        setClaim('');
        setRemark('');
        setAccountname('');  

        setMultidimensionalArray([])
        setInputData([])
        
        NotificationManager.success(response.data.response);
      }
      
  });
    
  };

  return (
    <div className="content">
        <div className="content ">
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div style={{ cursor: "pointer" }} >
                <div className="form-group">
                  {/* <form id="form" method="post"> */}
                      <div className='card ' style={{ marginTop: 0 , padding: '0px 10px' }}>
                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='awb_scan'>Scan(AWB)</label>
                          </div>
                          <div className='col-md-6'>
                              <div className='form-group'>
                                  <input type='text' className='form-control' name="awb_scan" id='awb_scan' value={awb} onChange={(e) => setAwb(e.target.value)} 
                                  // onKeyUp={get_qty} 
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                    get_qty();
                                    }}
                                   />
                              </div>
                          </div>
                        </div>

                        <MultipleBox items={multidimensionalArray} onInputChange={handleInputChange} />
                        
                        {/* <div id="skubox_0" className="skubox">
                          <div className='row'>
                            <div className='col-md-2'>
                              <label htmlFor='sku'>SKU</label>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <input type='text' className='form-control' name="sku"  id='sku' value={sku} onChange={(e) => setSku(e.target.value)} />
                                </div>
                            </div>

                            <div className='col-md-1'>
                              <label htmlFor='quantity'>Return Quantity</label>
                            </div>
                            <div className='col-md-2'>
                                <div className='form-group'>
                                    <input type='text' className='form-control' name="quantity"  id='quantity' value={quantity} onChange={(e) => setQuantity(e.target.value)} readOnly={true} />
                                </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-2'>
                              <label htmlFor='action'>Action</label>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <select name="qc_action" id="qc_action" className="form-control" value={qcaction} onChange={handleSelect} >
                                      <option value="saleable">Saleable</option>
                                      <option value="non_saleable">Non-Saleable</option>
                                      <option value="damage">Damage</option>
                                    </select>
                                </div>
                            </div>
                            
                          </div>
                        </div> */}

                        {/* <div className="row">
                            <div className='col-md-2'>&nbsp;</div>
                            {saleableInputDisplay?( 
                              <div className='col-md-3'>
                                <label>Saleable</label>
                                <input type='text' name='saleableQty' value={saleableQuantity} onChange={(e) => setSaleableQuantity(e.target.value)} /></div>
                            ):''}
                            {nonsaleableInputDisplay?( 
                              <div className='col-md-3'>
                                <label>Non-Saleable</label>
                                <input type='text' name='nonsaleableQty' value={nonSaleableQuantity} onChange={(e) => setNonSaleableQuantity(e.target.value)}  /></div>
                            ):''}
                            {damageInputDisplay?( 
                              <div className='col-md-2'>
                                <label>Damage</label>
                                <input type='text' name='damageQty' value={damageQuantity} onChange={(e) => setDamageQuantity(e.target.value)}  /></div>
                            ):''}
                        </div> */}
 
                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='product_quantity'>Claim</label>
                          </div>
                          <div className='col-md-1'>
                              <div className='form-group'>
                                  <span>Yes</span>&nbsp;
                                  <input type='radio' name='claim' value="1" checked={claim === "1"} onChange={onOptionChange} />
                              </div>
                          </div>
                          <div className='col-md-1'>
                              <div className='form-group'>
                                  <span>No</span>&nbsp;
                                  <input type='radio' name='claim' value="2" checked={claim === "2"} onChange={onOptionChange} />
                              </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='remark'>Remark</label>
                          </div>
                          <div className='col-md-6'>
                              <div className='form-group'>
                                <textarea className='form-control' id='remark' name="remark" value={remark} onChange={(e) => setRemark(e.target.value)} ></textarea>
                              </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2'>
                            <label htmlFor='producaccount_namet_quantity'>Account Name</label>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                                <input type="text" className='form-control' id='customer_name' name="customer_name" value={accountname || ""} readOnly={true} />
                                <input type="hidden" className='form-control' id='scan_type' name="scan_type" value={scantype} />
                            </div>
                          </div>
                        </div>
                          
                        <div className='col-md-2'>
                            {/* <CustomButton fill type='submit' name='button' 
                            onClick={() => {
                              scanUpdate();  // This runs only on button click
                            }}
                            >Update</CustomButton> */}
                            <button
                              style={{
                                background: "rgb(255, 160, 0)",
                                borderColor: "rgb(255, 160, 0)",
                                fontSize: "12px",
                                fontWeight: "300",
                              }}
                              className="btn btn-primary btn-fill"
                              // onClick={() => scanUpdate()}
                              onClick={handleUpdate}
                            >
                              Update
                            </button>
                        </div>
                      </div>
                  {/* </form> */}
                </div>
              </div>
             
            </div>
          </div>
        </div>
     
    </div>
  );
}
export default OrderList;
