import React, { useState, useEffect } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "../../assets/css/productList.css";
import _ from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton';

const OnshelfBulkUpload = () => {
  const token = localStorage.getItem("token");
  // const decode = jwt_decode(token);
  const [csvData, setCsvData] = useState([]);
  
  const modalStyle = {
    margin: "auto",
    position: "relative",
  };

  useEffect(() => {

  }, []);

  //Add Product from CSV File
  const addCSvProduct = (e) => {
    e.preventDefault();
    
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('id', localStorage.getItem("userId"));
    // console.log(scvdata)

    // return false;
    axios
      .post('/wms/scanBulk', scvdata)
      .then((res) => {
        console.log(res.data.error);
		// if (res.data.includes('success')) {
		if (res.data.error==0) {
          NotificationManager.success('File uploaded Successfully');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.Response.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

return (
    <div className="mainBox">
      <br />
    <div className="container-fluid">
      <div className="card" style={{padding: "1em"}}>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <label htmlFor='productImage' style={{fontSize: 15 }}>Upload Return File</label>
            <form onSubmit={addCSvProduct} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
              <div className='form-group' style={{margin: "0"}}>        
                <div id='fileContents'></div>
                <input
                  type='file'
                  className='form-control text-center'
                  name='avatar'
                  onChange={(e) => { 
                  setCsvData(e.target.files);
                  }}
                  encType='multipart/form-data'
                  accept='.csv'
                />
                </div>
                <div className='' style={{margin: "0 0 0 10px"}}>
                <CustomButton fill type='submit'>
                  Upload Return
                </CustomButton>
                </div>
            </form>
            <a href="https://shopperrcdn.shopperr.in/inward/6613cc084184e227e4815e4e2025/1/BHEzE3_6613cc084184e227e4815e4e.csv" target="_blank">Sample Csv</a>
          </div>
        </div>
      </div>
    </div>

  
  </div>
  );
};

export default OnshelfBulkUpload;
