import React, { Component } from "react";
import { Grid, Row, Col, Table, Button, Modal } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomButton from "../components/CustomButton/CustomButton.jsx";
import Card from "../components/Card/Card.jsx";
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import moment from "moment";
import "react-tagsinput/react-tagsinput.css";
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;
export default class walletSummary extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      currentBalance: 0,
      txnData: [],
      originalTxnData: [],
      currentPage: 1,
      showPerPage: 50,
      pageCount: 0,
      tnxTotalCount: 0,
      tnxTotalCount: 0,
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() {
    this.getMerchantBalance();
    this.getTnxData(this.state.currentPage, this.state.showPerPage);
    // console.log(this.getSupplier)
  }
  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value });
  };
  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value });
  };
  sortByDate = () => {
    const { startDate, endDate, originalTxnData } = this.state;
    const filteredData = originalTxnData.filter((item) => {
      const transactionDate = moment(item.crDate).format("YYYY-MM-DD");
      return moment(transactionDate).isBetween(startDate, endDate, null, "[]");
    });
    this.setState({ txnData: filteredData });
  };

  getMerchantBalance() {
    let data = {
      _id: jwt_decode(localStorage.getItem("token")).id,
    };
    axios.post("/shopperrds/getMerchant", data).then((response) => {
      if (response.data.error === 0) {
        this.setState({ currentBalance: response.data.response.balance });
      } else {
        console.log(response);
      }
    });
  }

  handlePageClick = (e) => {
    this.getTnxData(e.selected + 1, this.state.showPerPage);
  };
  getTnxData(page, perPage) {
    this.setState({ loading: true });
    axios
      .get(
        `/shopperrds/getPaymentHistory?page=${page}&limit=${perPage}&id=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        this.setState({
          txnData: result.data.items,
          originalTxnData: result.data.items, // Update originalTxnData as well
          tnxTotalCount: result.data.totalCount,
          pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),
          currentPage: page,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }
  render() {
    const { startDate, endDate } = this.state;
    return (
      <div>
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={this.state.loading}
        />
        <br></br>
        <div className="container-fluid">
          <div className="card" style={{ padding: "0.5em" ,backgroundColor:"white" }}>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 18,
                }}
              >
                <input
                  required
                  className=" border focus:outline-none text-sm  rounded-full w-full p-0 px-3 text-grey-darker"
                  id="startDate"
                  type="date"
                  autoComplete="bday-day"
                  placeholder="Start from"
                  max={new Date()}
                  min={new Date("20-02-2019")}
                  value={startDate}
                  onChange={this.handleStartDateChange}
                  style={{ height: 45, width: "400px" }}
                />

                <input
                  required
                  placeholder="To date"
                  className=" border focus:outline-none text-sm  rounded-full w-full p-0 px-3 text-grey-darker"
                  id="endDate"
                  type="date"
                  autoComplete="bday-day"
                  max={new Date()}
                  min={new Date("20-02-2019")}
                  value={endDate}
                  onChange={this.handleEndDateChange}
                  style={{
                    height: 45,
                    width: "400px",
                    marginLeft: "20px",
                  }}
                />
                <div
                  style={{
                    backgroundColor: "rgb(255 160 0)",
                    color: "#fff",
                    fontWeight: "400",
                    width: "300px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                  onClick={this.sortByDate}
                >
                  <div>Show</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card" style={{ padding: "1em" }}>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                Your Balance : ₹
                {this.state.txnData.length > 0
                  ? this.state.txnData[0].afterAmt
                  : "N/A"}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 text-right"></div>
              <div className="col-sm-2 col-md-2 col-lg-2 text-right">
                <Link to="/merchant/add-balance">
                  <button
                    className="btn btn-fill"
                    style={{
                      textTransform: "uppercase",
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    Add Balance
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="table-responsive">
            <table className="table table-hover ProductListTable" style={{backgroundColor:"white"}}>
              <thead>
                <tr className="d-flex">
                  <th scope="col" className="text-center">
                    No
                  </th>
                  <th scope="col" className="text-center">
                    Transaction Amount
                  </th>
                  <th scope="col" className="text-center">
                    Previous Amount
                  </th>
                  <th scope="col" className="text-center">
                    Final Amount
                  </th>
				  <th scope="col" className="text-center">
                    TRANSACTION ID
                  </th>
                  <th scope="col" className="text-center">
                    REMARK
                  </th>
                  <th scope="col" className="text-center">
                    {" "}
                    Type
                  </th>
                  <th scope="col" className="text-center">
                    {" "}
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(this.state.txnData) ? (
                  this.state.txnData
                    .sort((a, b) => new Date(b.crDate) - new Date(a.crDate))
                    .map((item, index) => {
                      return (
                        <tr key={index} className="d-flex">
                          <td className="text-center">
                            {(this.state.currentPage - 1) *
                              this.state.showPerPage +
                              (index + 1)}
                          </td>
                          <td className="text-center">₹{item.billAmt}</td>
                          <td className="text-center">₹{item.beforeAmt}</td>
                          <td className="text-center">₹{item.afterAmt}</td>
						  <td className="text-center">{item.pgOrderId}</td>
                          <td className="text-center">
                            {item.systemId ? (
                              <Link to={`/merchant/order/${item.systemId}`}>
                                 {item.comment}
                                  
                              </Link>
                            ) : (
								item.comment
                            )}
                          </td>

                          <td className="text-center">
                            {item.billType === 1
                              ? "Dr"
                              : item.billType === 2
                              ? "Cr"
                              : "Refunded"}
                          </td>
                          <td className="text-center">
                            {moment(item.crDate).format("DD-MM-YYYY h:m A")}
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center"
                      style={{ fontSize: "20px" }}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={10}
            onPageChange={this.handlePageClick.bind(this)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}
