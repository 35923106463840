import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import "../assets/css/Login.css";
import { NotificationManager } from "react-notifications";

const MerchantLogin = ({ location }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && jwt_decode(token).category === "merchant") {
      setisLoggedIn(true);
      if (location.search) {
        const redirectUrl = location.search.split("=")[1];
        window.location.href = redirectUrl + "/" + token;
      }
    }
  }, [location]);

  const userLogin = (e) => {
    e.preventDefault();
    if (!(email && password)) {
      return NotificationManager.error("Please Fill All the Fields");
    }

    // Validate Email format
    if (email) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        return NotificationManager.error("Please enter a valid email address.");
      }
    }

    // Prepare data to send in the login request
    const obj = {
      email: email,
      password: password,
      access_token: "jddkfjhsfksldf$10$JQBUiGMjLe7FU^54$5I8"
    };

    // Make login request
    axios
      .post("/auth/login", obj)
      .then((data) => {
        const token = data.data.token;
        const userId = data.data.user.id;

        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Login successfully!",
          showConfirmButton: false,
          timer: 1500
        });

        setStatus("Login Success");
        setisLoggedIn(true);

        if (location.search && jwt_decode(token).category === "merchant") {
          const redirectUrl = location.search.split("=")[1];
          window.location.href = `${redirectUrl}/${token}`;
        }
      })
      .catch((err) => {
        // Check if the error is due to incorrect credentials
        if (err.response && err.response.data.message === "Invalid credentials") {
          NotificationManager.error("Incorrect email or password.");
        } else {
          NotificationManager.error("An error occurred. Please try again.");
        }

        setStatus("User Id or Password not matched with reseller account");
      });
  };

  return (
    <div className="wrapper" id="wrapper-login">
      {isLoggedIn === true ? (
        <Redirect to="/auth" />
      ) : (
        <div className="form-wrapper-login" id="form-wrapper-login">
          <h2 style={{ margin: "5px" }}>Reseller Login</h2>
          <form onSubmit={userLogin}>
            <div className="email">
              <label
                htmlFor="email"
                style={{ textAlign: "left", fontSize: "16px", color: "black" }}
              >
                Email
              </label>
              <input
                className="input-box-login"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="name@example.com"
              />
            </div>
            <div className="password">
              <label
                htmlFor="password"
                style={{ textAlign: "left", fontSize: "16px", color: "black" }}
              >
                Password
              </label>
              <input
                className="input-box-login"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter Password"
              />
            </div>
            <br />
            <div className="submit-login">
              <button
                className="btn btn-fill"
                style={{
                  backgroundColor: "rgb(255, 160, 0)",
                  color: "rgb(255, 255, 255)",
                  fontWeight: "500",
                  height: "40px",
                  borderRadius: 4,
                  border: "0px",
                  fontSize: "15px"
                }}
                type="submit"
              >
                LOGIN
              </button>
            </div>
            <div className="submit-login">
              <p>
                <a
                  href="/merchant/forgot-password"
                  style={{
                    color: "#ffa000",
                    textAlign: "center",
                    margin: "10px"
                  }}
                >
                  Forgot Password?
                </a>
              </p>
            </div>
            <div className="submit-login">
              <p className="text-center" style={{ marginTop: "5px" }}>
                Create New Account?{" "}
                <a href="merchant-signup" style={{ color: "#ffa000" }}>
                  Sign Up
                </a>
              </p>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default MerchantLogin;
