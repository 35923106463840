
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from 'react-router-dom'
import jwt_decode from 'jwt-decode';
import { getPriceFormatter } from '../../helpers/index.js';
import customStyle from '../../assets/css/custom.css';

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
    const [orders, setOrders] = useState([])
    const [users, setUsers] = useState([]);
    const [pageData, setPageData] = useState([])
    const [errormessage, setErrorMessage] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [showPerPage, setShowPerPage] = useState(50)
    const [shippedPerDownolaod, setShippedPerDownolaod] = useState(40000)
    const [searchtxt, setSearchTxt] = useState("")
    const [search, setSearch] = useState("")
    const [tempCustomerData, setTempCustomerData] = useState([])
    const [loading, setLoading] = useState(true)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setTodate] = useState("")
    const [error, setErrors] = useState({})
    const [storeDomain, setStoreDomain] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [source, setOrderSource] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [lastSearchParams, setLastSearchParams] = useState({});
    const [totalOrders, setTotalOrders] = useState(0);

    const [searchOrderNumber, setSearchOrderNumber] = useState("");
    const [searchMarketplaceOrderId, setSearchMarketplaceOrderId] = useState("");
    const [searchBuyerLoginName, setSearchBuyerLoginName] = useState("");
    const [searchTrackingNumber, setSearchTrackingNumber] = useState("");
    const [searchOrderStatus, setSearchOrderStatus] = useState("");
    const [searchShippingStatus, setSearchShippingStatus] = useState("");
    const [searchReturnStatus, setSearchReturnStatus] = useState("");
    const [sku, setSku] = useState("");

    useEffect(() => {
        setLoading(false)
        getOrders(currentPage, showPerPage, { refresh: 1 },)
    }, []);
    const getOrders = (currentPage, showPerPage, searchparams = {}) => {
		console.log(searchparams);
        setLoading(true);
        if (!searchparams.refresh) {
            searchparams = { ...searchparams, storeDomain, phoneNumber, source };
        } else {
            delete searchparams.refresh;
        }

        setLastSearchParams(searchparams);
        const qs = Object.keys(searchparams).map(key => `${key}=${searchparams[key]}`).join('&');
        axios.get(`/admin/adminOrders?page=${currentPage}&limit=${showPerPage}&${qs}`, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        }).then(res => {
            setLoading(false);
            // console.log('API Response:', res.data); // Log the response

            if (!res.data.hasOwnProperty('message')) {
                setPageCount(Math.ceil(res.data.count / showPerPage));
                setOrders(res.data || []);
                setPageData(res.data.results || []); // Ensure it is set as an array
                setUsers(res.data.users || []); // Ensure it is set as an array
                setTotalOrders(res.data.count);

                // console.log('Page Data:', res.data.results); // Log the page data
                // console.log('count :', res.data.results.count ); // Log the page data
                // console.log('Users Data:', res.data.users); // Log the users data
            } else {
                setErrorMessage(res.data.message);
            }
        })
            .catch(err => {
                setLoading(false);
                console.log("Error", err);
            });
    };
    // console.log(getOrders)


    const handlePageClick = (e) => {
        setLoading(true)
        setCurrentPage(e.selected + 1);
        getOrders(e.selected + 1, showPerPage, lastSearchParams);
    }

    const handleChange = (e) => {
        document.getElementById("searchOrder").value = ""
        setFromDate("")
        setTodate("")
        getOrders(currentPage, showPerPage, {})
    }

    const handleSearchOrder = e => {
        setSearch(e.target.value)
    }

    const handleSearchPhoneNumber = e => {
        e.target.value = e.target.value.replace(/[^\d]/g, '');
        setPhoneNumber(e.target.value)
    }
    const handleSearchStoreDomain = e => {
        setStoreDomain(e.target.value)
    }
    const handleSearchBySource = e => {
        setOrderSource(e.target.value);
    }
    const handleFromdate = fromDate => {
        setFromDate(fromDate)
        setErrors({})
    }

    const handleToDate = toDate => {
        setTodate(toDate)
        setErrors({})
    }

    function showDateRangeData() {
        let errors = {};

        let filters = {
            text: searchtxt,
            fromDate: fromDate,
            toDate: toDate,
            searchOrderStatus: searchOrderStatus,
            marketplace: source,
        };
		//console.log(filters);
        getOrders(1, showPerPage, filters);

        // if(!isEmpty(fromDate) && !isEmpty(toDate)){ 
        //     let d = new Date(fromDate)
        //     if(d.getFullYear() > 9999){
        //         errors.fromDate = "From Date must be less than 9999"
        //     }else if(d.getFullYear() < 1950){
        //         errors.fromDate = "From Date must be greater than 1950"
        //     }
        //     let d1 = new Date(toDate)
        //     if(d1.getFullYear() > 9999){
        //         errors.toDate = "To Date must be less than 9999"
        //     }else if(d1.getFullYear() < 1950){
        //         errors.toDate = "To Date must be greater than 1950"
        //     }
        //     if(isEmpty(errors)){
        //         const date1 = new Date(fromDate);
        //         const date2 = new Date(toDate);
        //         const diffTime = date2 - date1
        //         const diffDays = (diffTime / (1000 * 60 * 60 * 24))
        //         if(diffDays < 0){
        //             errors.toDate = "End date needs to be after From date";
        //         }else {
        //             getOrders(1,showPerPage,{fromDate,toDate,text:search})
        //         }
        //     }
        //     setErrors(errors)
        // } else{
        //     getOrders(1,showPerPage,{text:search})
        // }


    }

    function downloadOrder() {
        let errors = {};

        let filters = {
            text: searchtxt,
            fromDate: fromDate,
            toDate: toDate,
            searchOrderStatus: searchOrderStatus,
            marketplace: source,
        };


        getDownloadOrders(currentPage, showPerPage, filters);

        // if(!isEmpty(fromDate) && !isEmpty(toDate)){ 
        //     let d = new Date(fromDate)
        //     if(d.getFullYear() > 9999){
        //         errors.fromDate = "From Date must be less than 9999"
        //     }else if(d.getFullYear() < 1950){
        //         errors.fromDate = "From Date must be greater than 1950"
        //     }
        //     let d1 = new Date(toDate)
        //     if(d1.getFullYear() > 9999){
        //         errors.toDate = "To Date must be less than 9999"
        //     }else if(d1.getFullYear() < 1950){
        //         errors.toDate = "To Date must be greater than 1950"
        //     }
        //     if(isEmpty(errors)){
        //         const date1 = new Date(fromDate);
        //         const date2 = new Date(toDate);
        //         const diffTime = date2 - date1
        //         const diffDays = (diffTime / (1000 * 60 * 60 * 24))
        //         if(diffDays < 0){
        //             errors.toDate = "End date needs to be after From date";
        //         }else {  
        //             getDownloadOrders(1,showPerPage,{fromDate,toDate,text:search})
        //         }
        //     }
        //     setErrors(errors)
        // } else if(search){
        //     getDownloadOrders(1,showPerPage,{text:search})
        // }else{
        //     getDownloadOrders(currentPage,showPerPage,{})
        // }
    }

    const getDownloadOrders = (currentPage, showPerPage, searchparams = {}) => {
        setLoading(true)
        searchparams = { ...searchparams, storeDomain, phoneNumber, source };
        const qs = Object.keys(searchparams)
            .map(key => `${key}=${searchparams[key]}`)
            .join('&');

        axios.get(`/admin/downloadAllOrderCsv?page=${currentPage}&limit=${showPerPage}&${qs}`).then(data => {
            let result = data.data;
            setLoading(false)
            if (result.error == 0) {
                window.open(result.response)
            } else if (result.error == 1) {
                alert(result.response);
            }
        })

        // axios.get(`/adminApi/downloadAllOrderCsv?page=${currentPage}&limit=${showPerPage}&${qs}`).then(data => {
        //     let result = data.data;
        //     setLoading(false)
        //     if(result.error == 0){
        //         window.open(result.response)
        //     }else if(result.error == 1){
        //         alert(result.response);
        //     } 
        // })
    }

    

    const handleSearchByOrderStatus = (e) => {
        setSearchOrderStatus(e.target.value);
    }

    return (
        <div className="content bdycontent">
            <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
            <div className="container-fluid">
                <div className="card" style={{ padding: "1em" }}>
                    <div className="row overrideRow">
                        <div className=" ">
                            <div className="form-group"> 
                               <label >Search</label>
                                <input type="text" className="form-control " placeholder="Search..." onChange={(e)=>setSearchTxt(e.target.value)} id="searchOrder" autoComplete="off" />
                            </div>
                        </div>

                        {/*<div className=" ">
                            <div className="form-group">
                                <label>Order Number</label>
                                <input type="text" className="form-control " placeholder="Order Number" onChange={(e) => setSearchOrderNumber(e.target.value)} id="searchOrderNumber" autoComplete="off" />
                            </div>
                        </div>
                        <div className=" ">
                            <div className="form-group">
                                <label>Marketplace Order Id</label>
                                <input type="text" className="form-control " placeholder="Marketplace Order Id" onChange={(e) => setSearchMarketplaceOrderId(e.target.value)} id="searchMarketplaceOrderId" autoComplete="off" />
                            </div>
                        </div>
                        <div className=" ">
                            <div className="form-group">
                                <label>Buyer Login Name</label>
                                <input type="text" className="form-control " placeholder="Buyer Login Name" onChange={(e) => setSearchBuyerLoginName(e.target.value)} id="searchBuyerLoginName" autoComplete="off" />
                            </div>
                        </div>
                        <div className=" ">
                            <div className="form-group">
                                <label>Tracking No</label>
                                <input type="text" className="form-control " placeholder="Tracking No" onChange={(e) => setSearchTrackingNumber(e.target.value)} id="searchTrackingNumber" autoComplete="off" />
                            </div>
                        </div>*/}

                        <div className="">
                            <div className="form-group">
                                <label>From Date &nbsp;<span onClick={() => document.getElementById("fromDate").value = ""} style={{ cursor: "pointer", color: "#42d0ed" }}>(Clear)</span></label>
                                <input type="date" className="form-control" id="fromDate" max="9999-12-01" min="1950-12-31" placeholder="From Date" value={fromDate} onChange={(e) => handleFromdate(e.target.value)} />
                                {
                                    !isEmpty(error.fromDate)
                                    &&
                                    <span className="text-danger">{error.fromDate}</span>
                                }
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label>To Date &nbsp;<span onClick={() => document.getElementById("toDate").value = ""} style={{ cursor: "pointer", color: "#42d0ed" }}>(Clear)</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="toDate"
                                    max="9999-12-01"
                                    min="1950-12-31"
                                    placeholder="To Date"
                                    value={toDate}
                                    onChange={(e) => handleToDate(e.target.value)}
                                />
                                {
                                    !isEmpty(error.toDate)
                                    &&
                                    <span className="text-danger">{error.toDate}</span>
                                }
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label>Order Status</label>
                                <select className="form-control" onChange={handleSearchByOrderStatus}>
                                    <option value="">All</option>
                                    <option value="PD">Paid</option>
                                    <option value="PR">Partial Refund</option>
                                    <option value="PP">Partial Payment</option>
                                    <option value="SS">Shipped</option>
                                    <option value="SU">Not Shipped</option>
                                    <option value="RN">Return</option>
                                </select>
                            </div>
                        </div>
                        {/*<div className="">
                            <div className="form-group">
                                <label>Shipping Status</label>
                                <select className="form-control" onChange={handleSearchByShippingStatus}>
                                    <option value="">All</option>
                                    <option value="SS">Shipped</option>
                                    <option value="SU">Not Shipped</option>
                                </select>
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label>Return Status</label>
                                <select className="form-control" onChange={handleSearchByReturnStatus}>
                                    <option value="">All</option>
                                    <option value="RN">Return</option>
                                </select>
                            </div>
                        </div>
                        <div className=" ">
                            <div className="form-group">
                                <label>Search By SKU</label>
                                <input type="text" className="form-control " placeholder="Search By SKU" onChange={(e) => setSku(e.target.value)} id="searchBySku" autoComplete="off" />
                            </div>
                        </div>*/}
                        <div className="">
                            <div className="form-group">
                                <label>Search By Marketplace</label>
                                <select className="form-control" onChange={handleSearchBySource}>
                                    <option value="">Select</option>
                                    <option value="1">Amazon</option>
                                    <option value="2">Shopify</option>
                                    <option value="3">Shopperr (Dropship)</option>
                                    <option value="4">Shopperr Global(D2C)</option>   */}
                                    <option value="5">Shopperr Global(B2B)</option>   */}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="btns-rows">
                        <div className="">
                            <label>&nbsp;</label>
                            <button style={{ background: "rgb(255, 160, 0)", borderColor: "rgb(255, 160, 0)", fontSize: "15px", fontWeight: "500" }} className="btn btn-primary btn-fill" onClick={() => showDateRangeData()}>Get</button>
                        </div>
                        <div className="">
                            <label>&nbsp;</label>
                            <button className="btn btn-primary btn-fill" style={{ background: "rgb(255, 160, 0)", borderColor: "rgb(255, 160, 0)", fontSize: "15px", fontWeight: "500" }} onClick={() => downloadOrder()}>Download</button>
                        </div>
                        <div className="">
                            <label>&nbsp;</label>
                            <button className="btn btn-primary btn-fill" style={{ background: "rgb(255, 160, 0)", borderColor: "rgb(255, 160, 0)", fontSize: "15px", fontWeight: "500" }} onClick={() => downloadOrder('shipped')}>Shipped Download</button>
                        </div>
                    </div>
                    {/*<div className="row">
                        

                        <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            <label>&nbsp;</label>
                            <button style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}} className="btn btn-primary btn-fill" onClick={() => showDateRangeData()}>Get</button>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            <label>&nbsp;</label>
                            <button className="btn btn-primary btn-fill" style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}} onClick={() => downloadOrder()}>Download</button>
                        </div>
                    </div>*/}
                </div>
            </div>

            <div className="container-fluid">
                <div className="card" style={{ padding: "1em" }}>
                    <div style={{ cursor: "pointer" }} onClick={() => getOrders(currentPage, showPerPage, { refresh: 1 })}>
                        <div className="form-group">
                            <h5 className="text-bold">Total Orders: <span className="text-muted">{totalOrders}</span>
                                &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                            </h5>
                        </div>
                    </div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Order Number</th>
                                <th scope="col">Seller</th>
                                <th scope="col">Buyer</th>
                                <th scope="col">marketplace Order ID</th>
                                <th scope="col">Marketplace</th>
                                <th scope="col">Order Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Shopperr Price</th>
                                <th scope="col">Consumer Price (Qty)</th>
                                <th scope="col">updated</th>
                                {/* <th scope="col">Total Items</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(pageData) && pageData.length > 0
                                    ?
                                    pageData
                                        .filter((val) => {
                                            if (search === "") {
                                                return val;

                                            } else if (
                                                val.customer.first_name?.toLowerCase().includes(search?.toLowerCase()) ||
                                                val.customer?.phone?.toString().includes(search) ||
                                                val.customer?.price?.toString().includes(search) ||
                                                val.customer?.orderNumber?.toString().includes(search) ||
                                                val.last_name?.toLowerCase().includes(search?.toLowerCase()) ||
                                                val.storeDomain?.toLowerCase().includes(search?.toLowerCase())
                                            ) {
                                                return val;
                                            }
                                        })
                                        .map(order => {
                                            // let d = new Date(order.orderDate);
                                            let orderQty = 0;
                                            let user = users.find(u => u._id === order.merchantId); // Find the user by ID
                                            {
                                                order.orderDetails.forEach((i) => {
                                                    orderQty += i.quantity
                                                })
                                            }
                                            return (
                                                <tr key={order._id}>
                                                    <td scope="row"><Link to={`/admin/order/${order._id}`}>{order.orderSerial}</Link></td>
                                                    <td>{user && `${user.fName} ${user.lName}`}</td> {/* Use user data */}
                                                    <td>{user && `${order.recipientName}`}</td>
                                                    <td>{order.mkpOrderId}</td>
                                                    <td>{order.storeDomain}</td>
                                                    {/* <td>{ `${d.getDate()}-${(d.getMonth() + 1)}-${d.getFullYear()}` }</td> */}
                                                    <td>{new Date(order.orderDate).toUTCString()}</td>

                                                    <td>
                                                        <img
                                                            title={
                                                                order.oStatus === "OB" ? "Bulk Ordered" : order.oStatus === "OC" ? " Order Cancelled" : " "
                                                            }
                                                            src={ 
                                                                order.oStatus === "OC"
                                                                ? "https://admin.shopperr.in/app/images/OrderStatus/OC.gif"
                                                                : "https://admin.shopperr.in/app/images/OrderStatus/OB.gif"}
                                                            alt="Order Status"

                                                        />
                                                        <img
                                                            title={
                                                                order.pStatus === "Non-Payment" ? "By default" : order.pStatus === "PD" ? "Paid" : order.pStatus === "PR" ? "Partial Refund" : order.pStatus === "PP" ? "Partial Payment" : " "
                                                            }
                                                            src={order.pStatus === "PD"
                                                                ? "https://admin.shopperr.in/app/images/OrderStatus/PD.gif"
                                                                : "https://admin.shopperr.in/app/images/OrderStatus/PU.gif"}
                                                            alt="Payment Status"

                                                        />
                                                        <img
                                                            title={
                                                                order.sStatus === "SU" ? "Not Shipped" : order.sStatus === "SS" ? "Shipped" : " "
                                                            }
                                                            src={
                                                                order.sStatus === "SS"
                                                                    ? "https://admin.shopperr.in/app/images/OrderStatus/SS.gif"
                                                                    : "https://admin.shopperr.in/app/images/OrderStatus/SU.gif"
                                                            }
                                                            alt="Shipping Status"
                                     
                                                        />


                                                        <img
                                                            title={
                                                                order.rStatus === "RR" ? "By default" : order.rStatus === "RN" ? "Return" : " "
                                                            }
                                                            src="https://admin.shopperr.in/app/images/OrderStatus/RN.gif"
                                                            alt="Return Status"
                                                        />
                                                        <img
                                                            title={order.dStatus === "DN" ? "No dispute" : order.dStatus === "DP" ? "Awaiting response" : order.dStatus === "DD" ? "Refused" : " "}
                                                            src={order.dStatus === "DD"
                                                                ? "https://admin.shopperr.in/app/images/OrderStatus/DD.gif"
                                                                :"https://admin.shopperr.in/app/images/OrderStatus/DN.gif"}
                                                            alt="Dispute Status"
                                                        />    
                                                        <img
                                                            title={order.commentStatus}
                                                            src={
                                                            "https://admin.shopperr.in/app/images/OrderStatus/CN.gif"}
                                                            alt="Comment Status"
                                                        />
                                                    </td>

                                                    <td>{getPriceFormatter(order.shopperrPrice)}</td>
                                                    <td>{getPriceFormatter(order.consumerPrice)} ({orderQty})</td>
                                                    <td>{order.updatedAt && new Date(order.updatedAt).toLocaleDateString()}</td>
                                                    {/* <td>{ order.products.length }</td> */}
                                                    <td><Link to={`/admin/order/${order._id}`}><i className="fa fa-eye" aria-hidden="true"></i></Link></td>
                                                </tr>
                                            );
                                        })
                                    :
                                    <tr>
                                        <td colSpan="8" className="text-center"><h4>No Data Found</h4></td>
                                    </tr>
                            }
                        </tbody>

                    </table>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={10}
                        onPageChange={(e) => handlePageClick(e)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </div>
    )

}
export default OrderList
