import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import ALogin from "./components/AdminLogin";
import SLogin from "./components/SupplierLogin";
import MLogin from "./components/MerchantLogin";
import MLogout from "./components/MerchantLogout";
import LLogin from "./components/LogisticUserLogin";
import Auth from "./components/Auth";
import AdminLayout from "./layouts/Admin.jsx";
import ItAdminLayout from "./layouts/ItAdmin.jsx";
import SupplierLayout from "./layouts/Supplier.jsx";
import ProductLayout from "./layouts/Products.jsx";
import LandingLayout from "./layouts/Landing.jsx";
import LandingPage from "./layouts/LandingPage.js";
import LandingPageCopy from "./layouts/Landing1.jsx";
import MerchantLayout from "./layouts/Merchant.jsx";
import LogisticUserLayout from "./layouts/LogisticUserLayout.js";
import FinanceLayout from "./layouts/Finance.jsx";
import MerchantSignup from "./views/MerchantSignup.jsx";
import ConnectStore from "./views/ConnectStore.jsx";
import SupplierForm from "./views/SupplierForm.jsx";
import Invoice from "./views/Invoice.jsx";
import SupplierTerms from "./views/SupplierTerms.jsx";
import AddProduct from "./views/AddProduct.jsx";
import TermsConditions from "./views/TermsConditions.jsx";
import PrivacyPolicy from "./views/PrivacyPolicy.jsx";
import ContactUs from "./views/ContactUs.jsx";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import ShopperrProduct from "./views/ShopperrProduct";
import AddShopifyApi from "./views/MerchantStoreApi";
import CustomerList from "./views/MerchantStoreCust";
import CustomerEdit from "./views/MerchantCustEdit";
import CustomerView from "./views/MerchantCustView";
import BarcodePo from "./views/BarcodePo";
import EditPo from "./views/SupplierEditPo";
import ShowOrder from "./views/MerchantOrderView";
import PerformaInvoice from "./views/PerformaInvoice";
import amazonConnection from "./views/amazonConnection";
import Cashfree from "./views/Cashfree";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Contact from "./views/contact";
import MerchantbulkUploadOrder from "./views/MerchantbulkUploadOrder";
import WmsLayout from "./layouts/Wms.jsx";
import PrintLayout from "./layouts/PrintWms.jsx";

import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LandingLayout} />

          <Route exact path="/landing" component={LandingPageCopy} />
          <Route exact path="/product/:id" component={SingleProduct} />
          <Route exact path="/new-header" component={LandingPage} />
          <Route exact path="/login-admin" component={ALogin} />
          <Route exact path="/login" component={MLogin} />
          // <Route exact path="/login-merchant" component={MLogin} />
          // <Route exact path="/logout-merchant" component={MLogout} />
          // <Route exact path="/login-logisticUser" component={LLogin} />
          <Route
            exact
            path="/merchant/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/merchant/reset-password"
            component={ResetPassword}
          />
          <Route
            exact
            path="/supplier/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/supplier/reset-password"
            component={ResetPassword}
          />

          <Route exact path="/merchant-signup" component={MerchantSignup} />
          <Route exact path="/connect-Store" component={ConnectStore} />
          <Route exact path="/supplier-form" component={SupplierForm} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/itadmin" render={(props) => <ItAdminLayout {...props} />} />
          <Route path="/auth" component={Auth} />
          <Route
            path="/supplier"
            render={(props) => <SupplierLayout {...props} />}
          />
          <Route
            path="/merchant"
            render={(props) => <MerchantLayout {...props} />}
          />
          <Route
            path="/wms"
            render={(props) => <WmsLayout {...props} />}
          />
          <Route
            path="/print"
            render={(props) => <PrintLayout {...props} />}
          />
          <Route
            path="/logisticUser"
            render={(props) => <LogisticUserLayout {...props} />}
          />
          <Route
            path="/finance"
            render={(props) => <FinanceLayout {...props} />}
          />
          <Route
            exact
            path="/invoice/:supplierId/:orderId"
            component={Invoice}
          />
          <Route exact path="/supplier-t&c" component={SupplierTerms} />
          <Route exact path="/terms&condtions" component={TermsConditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route path="/shopify-api" component={AddShopifyApi} />

          <Route path="/shopperr/:id" component={ShopperrProduct} />

          <Route path="/customers" exact component={CustomerList} />
          <Route path="/edit-customer/:id" component={CustomerEdit} />
          <Route path="/customer/:id" exact component={CustomerView} />
          <Route path="/barcode" component={BarcodePo} />
          <Route path="/poedit/:id" component={EditPo} />
          <Route path="/order/:id" component={ShowOrder} />
          <Route path="/pinvoice/:id/:method" component={PerformaInvoice} />
          <Route exact path="/amazon/connect" component={amazonConnection} />
          <Route exact path="/success" component={Cashfree} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <style>{`input::placeholder {color: #565656 !important; }`}</style>
      </div>
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
