import React, { useState } from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../assets/css/merchantSignup.css";
import { NotificationManager } from "react-notifications";
import SelectCountries from "../assets/SelectCountries.js";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

function MerchantSignup(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [gst, setGst] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("");
  const [value, setValue] = useState();
  const [btnCheck, setBtnCheck] = useState(false);
  const [otp, setotp] = useState("");
  const [userOtp, setUserOtp] = useState("");
  const submitOtp = async (e) => {
    e.preventDefault();
    if (validateOtp()) {
      if (otp == userOtp) {
        const obj = {
          firstName: firstName,
          lastName: lastName,
          phoneNo: phoneNo,
          email: email,
          company: company,
          pincode: pincode,
          country: country,
          city: city,
          state: state,
          address: address,
          gst: gst,
          password: password,
        };
        try {
          let res = await axios.post("/auth/signUpMerchant", obj);
          if (res.data.status == 1) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Registered successsfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setEmail("");
            setFirstName("");
            setLastName("");
            setAddress("");
            setCompany("");
            setPincode("");
            setCountry("");
            setCity("");
            setState("");
            setGst("");
            setPhoneNo("");
            setValue();
            setPassword("");
            setBtnCheck(true);
            props.history.push("/login-merchant");
          } else {
            NotificationManager.error(res.data);
          }
        } catch (error) {
          NotificationManager.error("Something unusual happened");
        }
      } else {
        NotificationManager.error("Invalid Otp");
      }
    }
  };
  const addMerchant = async (e) => {
    e.preventDefault();
    if (validate()) {
      const obj = {
        firstName: firstName,
        lastName: lastName,
        phoneNo: phoneNo,
        email: email,
        company: company,
        pincode: pincode,
        country: country,
        city: city,
        state: state,
        address: address,
        gst: gst,
        password: password,
      };
      try {
        let res = await axios.post("/auth/signUpOtp", obj);
        if (res.data.status == 1) {
          setotp(res.data.Otp);
        } else {
          NotificationManager.error(res.data);
        }
      } catch (error) {
        NotificationManager.error('Something unusual happened');
      }
    }
  };

  const validateOtp = () => {
    let errors = {};
    let isValid = true;
    if (!userOtp) {
      return NotificationManager.error("Please enter OTP");
      isValid = false;
      errors["userOtp"] = "Please enter OTP.";
    }
    setErrors(errors);
    return isValid;
  };

  const validate = () => {
    let errors = {};
    let isValid = true;
    if (
      !(
        firstName ||
        lastName ||
        email ||
        company ||
        phoneNo ||
        pincode ||
        password ||
        gst ||
        address
      )
    ) {
      return NotificationManager.error("Please Fill All the Fields");
    }
    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        return NotificationManager.error("Please enter valid email address.");
      }
    }
    if (!phoneNo) {
      return NotificationManager.error("Please Fill All the Fields");
    } else if (typeof phoneNo !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phoneNo)) {
        return NotificationManager.error("Please enter only number");
      } else if (phoneNo.length != 10) {
        return NotificationManager.error("Please enter valid mobile number");
      }
    }

    // Previous validation code

    // if (!firstName) {
    //   isValid = false;
    //   errors["firstname"] = "Please enter your First Name.";
    // }
    // if (!lastName) {
    //   isValid = false;
    //   errors["lastname"] = "Please enter your Last Name.";
    // }

    // if (!email) {
    //   isValid = false;
    //   errors["email"] = "Please enter your email Address.";
    // }
    // if (email) {
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );
    //   if (!pattern.test(email)) {
    //     isValid = false;
    //     errors["email"] = "Please enter valid email address.";
    //   }
    // }

    // if (!company) {
    //   isValid = false;
    //   errors["company"] = "Please enter your Company Name.";
    // }

    // if (!phoneNo) {
    //   isValid = false;
    //   errors["phoneNo"] = "Please enter your mobile number.";
    // } else if (typeof phoneNo !== "undefined") {
    //   var pattern = new RegExp(/^[0-9\b]+$/);
    //   if (!pattern.test(phoneNo)) {
    //     isValid = false;
    //     errors["phoneNo"] = "Please enter only number.";
    //   } else if (phoneNo.length != 10) {
    //     isValid = false;
    //     errors["phoneNo"] = "Please enter valid mobile number.";
    //   }
    // }
    // if (!pincode) {
    //   isValid = false;
    //   errors["pincode"] = "Please enter your Pincode.";
    // }
    // if (!password) {
    //   isValid = false;
    //   errors["password"] = "Please enter your Password.";
    // }
    // if (!gst) {
    //   isValid = false;
    //   errors["gst"] = "Please enter your Gst.";
    // }

    // if (!address) {
    //   isValid = false;
    //   errors["address"] = "Please enter your Address.";
    // }

    setErrors(errors);
  //  console.log(isValid);
    return isValid;
  };

  return (
    <div>
     
      <div
        className="signup-form"
        style={{ position: "relative", display: "flex" }}
      >
      
        {otp ? (
        
          <div className="sign-up-form " style={{ margin: "15px auto" }}>
            <h1 style={{ margin: "10px 0 0 10px" }}>OTP</h1>
            <form onSubmit={(e) => submitOtp(e)}>
              <input
                type="text"
                className="input-box"
                placeholder="OTP"
                value={userOtp}
                onChange={(e) => setUserOtp(e.target.value)}
              />
              <div className="text-danger">{errors.userOtp}</div>
              <p className="text-center">
                Please check your email and enter the OTP.
              </p>
              <button
                type="submit"
                className="btn btn-fill"
                style={{
                  backgroundColor: "rgb(255, 160, 0)",
                  color: "rgb(255, 255, 255)",
                  fontWeight: "500",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                  border: "0px",
                  fontSize: "15px",
                }}
              >
                SUBMIT
              </button>
            </form>
          </div>
        ) : (
        
          <div className="sign-up-form" style={{ margin: "20px auto" }}>
            <h2 style={{ margin: "0" }}>Sign Up</h2>
            <form
              onSubmit={(e) => addMerchant(e)}
              style={{ marginBottom: "0px", textAlign: "center" }}
            >
              <div className="sign-up-form-div">
                <input
                  type="firstName"
                  className="input-box"
                  placeholder=" First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <div className="text-danger">{errors.firstname}</div>
                <input
                  type="lastName"
                  className="input-box"
                  placeholder=" Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <div className="text-danger">{errors.lastname}</div>
              </div>

              <div className="text-danger">{errors.email}</div>
              <div className="sign-up-form-div">
                <input
                  type="company"
                  className="input-box"
                  placeholder=" Company Name"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <div className="text-danger">{errors.company}</div>

                <input
                  type="number"
                  className="input-box"
                  placeholder="Phone number"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
                <div className="text-danger">{errors.phoneNo}</div>
              </div>
              <input
                type="text"
                className="input-box"
                placeholder=" Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {/* <input
                                type='state'
                                className='input-box'
                                placeholder=' State'
                                value={state}
       b                          onChange={(e) => setState(e.target.value)}
                                required
                            />

                            <input
                                type='city'
                                className='input-box'
                                placeholder=' City'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            /> */}
              <div
                className="fullName form-group "
                style={{ margin: "0px", padding: "10px 0px" }}
              >
                <SelectCountries
                  className="input-box"
                  countrySetter={setCountry}
                  stateSetter={setState}
                  citySetter={setCity}
                />
              </div>
              <div className="sign-up-form-div">
                <input
                  type="pincode"
                  className="input-box"
                  placeholder=" Pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
                <div className="text-danger">{errors.pincode}</div>

                <input
                  type="password"
                  className="input-box"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="text-danger">{errors.password}</div>
              </div>
              <div className="sign-up-form-div">
                <input
                  type="gst"
                  className="input-box"
                  placeholder=" GST No"
                  value={gst}
                  minLength={15}
                  maxLength={15}
                  pattern={
                    "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                  }
                  onChange={(e) => setGst(e.target.value)}
                />
                <div className="text-danger">{errors.gst}</div>

                <input
                  type="address"
                  className="input-box"
                  placeholder=" Billing Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <div className="text-danger">{errors.address}</div>
              </div>
              <p className="text-center" style={{ margin: "10px 0px" }}>
                <span className="check">
                  <input type="checkbox" />
                </span>
                I agree to the terms & conditions
              </p>
              <button
                type="submit"
                className="btn btn-fill"
                style={{
                  backgroundColor: "rgb(255, 160, 0)",
                  color: "rgb(255, 255, 255)",
                  fontWeight: "500",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                  border: "0px",
                  fontSize: "15px",
                }}
              >
                SIGN UP
              </button>

              <p style={{ marginTop: "5px" }}>
                Do you have an account ?{" "}
                <a href="login-merchant" style={{ color: "#ffa000" }}>
                  Sign In
                </a>
              </p>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default MerchantSignup;
