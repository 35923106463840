 import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2'
import '../assets/css/Login.css';
import { NotificationManager } from 'react-notifications';
const ForgetPassword = () => {
  	const [email, setEmail] = useState(''); 
  	const [userType, setUserType] = useState(''); 
  	const [errors, setErrors] = useState({}); 
  	const [success, setSuccess] = useState('');
  	useEffect(() => {
 		setUserType(window.location.pathname.split('/')[1]) 
  	}, []);

  	const forgotPassword = (e) => {
    	e.preventDefault();
    	if(validateEmail()) {
	    	const obj = {
	      		email: email,
	      		userType : userType
	    	}; 
	    	axios.post('/auth/reset-password', obj).then((data) => {
	         	if(data.data.status == 0){
	         		 setSuccess(1)
	         	}else{
	         		NotificationManager.error(data.data.response);
	         	}
	      	}).catch((err) => {
	        	NotificationManager.error('Something unusual happened');
	      	});
	    }
  	};

  	const validateEmail = () => {
        let errors = {};
        let isValid = true;
        if (!email) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";

        } 
        if ( email) { 
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }
        setErrors(errors) 
        return isValid;
    }
  	return (
  		<div>
  			{
  				success ? (
  					<div className='wrapper' id='wrapper-login'> 
			        	<div className='form-wrapper-login' id='form-wrapper-login'>
			        		<h2 style={{ color: 'black' , fontSize: '24px' , fontWeight: '500' }}>Reset Link Send!</h2> 
			          		<p style={{ textAlign: 'justify' , color: 'darkgreen' }}>Password reset link successfully send in your email.({email})</p>
			        		<a href="/login" style={{ textAlign : 'center' }}>Go To Login</a> 
			        	</div> 
			    	</div>
				) : (
					<div className='wrapper' id='wrapper-login'> 
			        	<div className='form-wrapper-login' id='form-wrapper-login'>
			          		<h2><strong>Reset Password</strong></h2>
			          		<form onSubmit={forgotPassword}>
				            	<div className='email'>
				              		<label htmlFor='email' style={{ textAlign: 'left', fontSize: '16px' }}>We will send you an email to reset your password.</label>
				              		<input 
				                		value={email}
				                		onChange={(e) => setEmail(e.target.value)}
				                		type='email'
				                		placeholder='name@example.com'
				              		/>
				              		<div className="text-danger">{errors.email}</div>
				            	</div> 
				            	<br />
					            <div className='submit-login'>
					              	<button className='btn btn-fill' style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px', 'fontSize': '15px' }} type='submit'>
					                	SUBMIT
					              	</button>
					            </div> 
				          	</form>
			        	</div> 
			    	</div>
				)
  			}
    	</div>
  	);
};
export default ForgetPassword;
