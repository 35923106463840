import React, { Component } from 'react';

class MultipleBox extends Component {

  constructor(props) {
    super(props);
    // Initialize state to manage input values and their visibility for multiple inputs
    this.state = {
      inputValues: this.props.items.map(() => ({
        saleableQuantity: '',
        nonSaleableQuantity: '',
        damageQuantity: '',
        qcAction: '',  // Adding qcAction to each input
      })),
      saleableInputDisplay: [true, true, true, true], // Defaulting all to true
      nonSaleableInputDisplay: this.props.items.map(() => false),
      damageInputDisplay: this.props.items.map(() => false),
      qcActionInputDisplay: this.props.items.map(() => false),  // Toggle for qcAction visibility
    };

  }


  // Handle changes for each input type (Saleable, Non-Saleable, Damaged, QC Action)
  handleChange = (index, type, value) => {
    // console.log('index')
    // console.log(index, type, value)
    const newInputValues = [...this.state.inputValues];
    newInputValues[index] = { ...newInputValues[index], [type]: value };

    if (!newInputValues[index].hasOwnProperty('qcAction')) {
      newInputValues[index] = { ...newInputValues[index], ['qcAction']: 'saleable' };
    }

    this.setState({ inputValues: newInputValues });

    // Send updated values to the parent component via onInputChange prop
    this.props.onInputChange(newInputValues);
  };

  // Toggle visibility of input fields (Saleable, Non-Saleable, Damaged, QC Action)
  toggleInputVisibility = (index, type) => {
    const newVisibilityState = { ...this.state };
    newVisibilityState[`${type}InputDisplay`][index] = !newVisibilityState[`${type}InputDisplay`][index];
    this.setState(newVisibilityState);
  };

  handleSelect = (e, index) => {
    const { value } = e.target;
    // setQcaction(e.target.value)
    // console.log(e.target.value)
    
    this.handleChange(index, 'qcAction', e.target.value)
    
    if(e.target.value == 'saleable'){

      this.setState(prevState => ({
        saleableInputDisplay: {
          ...prevState.saleableInputDisplay,
          [index]: true,
        },
        saleableInput: {
          ...prevState.saleableInput,
          [index]: true,
        }
      }));
      
    }

    if(e.target.value == 'non_saleable'){

      this.setState(prevState => ({
        nonSaleableInputDisplay: {
          ...prevState.nonSaleableInputDisplay, // Keep existing quantities
          [index]: true, // Update quantity for the specific skubox
        },
        nonsaleableInput: {
          ...prevState.nonsaleableInput, // Keep existing quantities
          [index]: true, // Update quantity for the specific skubox
        }
      }));
      
      // if(nonsaleableInput==false){
      //   setNonsaleableInputDisplay(true)
      // }
      
      // setNonsaleableInput(true)
    }

    if(e.target.value == 'damage'){

      this.setState(prevState => ({
        damageInputDisplay: {
          ...prevState.damageInputDisplay, // Keep existing quantities
          [index]: true, // Update quantity for the specific skubox
        },
        damageInput: {
          ...prevState.damageInput, // Keep existing quantities
          [index]: true, // Update quantity for the specific skubox
        }
      }));
      
      // if(damageInput==false){
      //   setDamageInputDisplay(true)
      // }
      
      // setDamageInput(true)
    }

  }

  render() {
    const { items } = this.props;  // Destructure items from props
    return (
      <div>
        {items.map((item, index) => (
          <div key={index}  className="skubox" style={{ paddingBottom: '10px' }}>
            <div className="row">
              <div className="col-md-2">
                <label>SKU ({item.sku})</label>
              </div>
              <div className="col-md-3">
                <input type="text" className="form-control" name="sku" id="sku" value={item.sku} readOnly />
              </div>

              <div className='col-md-1'>
                <label htmlFor='quantity'>Return Quantity</label>
              </div>
              <div className='col-md-2'>
                  <div className='form-group'>
                      <input type='text' className='form-control' name="quantity"  id='quantity' value={item.qty}  readOnly={true} />
                  </div>
              </div>

              <div className='col-md-3'>
                <img src={item.imgUrl} width="90" />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-2'>
                <label htmlFor='action'>Action</label>
              </div>
              <div className='col-md-6'>
                  <div className='form-group'>
                      <select name="qc_action" id="qc_action" className="form-control" value={this.props.qcaction} 
                      onChange={(e) => this.handleSelect(e, index)}  
                      // onChange={(e) => this.handleChange(index, 'qcAction', e.target.value)}
                      >
                        <option value="saleable">Saleable</option>
                        <option value="non_saleable">Non-Saleable</option>
                        <option value="damage">Damage</option>
                      </select>
                  </div>
              </div>
              
            </div>

            {/* Saleable Input */}
            <div className="row">
              <div className="col-md-2">&nbsp;</div>
              {this.state.saleableInputDisplay[index] && (
                <div className="col-md-3">
                  <label>Saleable</label>
                  <input
                    type="text"
                    name="saleableQty"
                    value={this.state.inputValues[index]?.saleableQuantity || ''}
                    onChange={(e) => this.handleChange(index, 'saleableQuantity', e.target.value)}
                  />
                </div>
              )}
            

            {/* Non-Saleable Input */}
            
              {this.state.nonSaleableInputDisplay[index] && (
                <div className="col-md-3">
                  <label>Non-Saleable</label>
                  <input
                    type="text"
                    name="nonSaleableQty"
                    value={this.state.inputValues[index]?.nonSaleableQuantity || ''}
                    onChange={(e) => this.handleChange(index, 'nonSaleableQuantity', e.target.value)}
                  />
                </div>
              )}
            

            {/* Damaged Input */}
            
              {this.state.damageInputDisplay[index] && (
                <div className="col-md-3">
                  <label>Damaged</label>
                  <input
                    type="text"
                    name="damageQty"
                    value={this.state.inputValues[index]?.damageQuantity || ''}
                    onChange={(e) => this.handleChange(index, 'damageQuantity', e.target.value)}
                  />
                </div>
              )}
            </div>

            {/* Buttons to toggle input visibility */}
            {/* <div className="row">
              <div className="col-md-2">&nbsp;</div>
              <div className="col-md-3">
                <button onClick={() => this.toggleInputVisibility(index, 'saleable')}>Toggle Saleable</button>
                <button onClick={() => this.toggleInputVisibility(index, 'nonSaleable')}>Toggle Non-Saleable</button>
                <button onClick={() => this.toggleInputVisibility(index, 'damage')}>Toggle Damaged</button>
                <button onClick={() => this.toggleInputVisibility(index, 'qcAction')}>Toggle QC Action</button>
              </div>
            </div> */}
          </div>
        ))}
      </div>
    );
  }
}

export default MultipleBox;
