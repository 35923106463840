import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useHistory } from "react-router-dom"; // useHistory for navigation
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';

const PayNow = () => {
  const location = useLocation();
  const history = useHistory(); // Initialize useHistory
  const { selectedOrders } = location.state || [];
  const [merchantBalance, setMerchantBalance] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentDone, setPaymentDone] = useState(false);
  const [updatedBalance, setUpdatedBalance] = useState(0);
  //console.log(selectedOrders)
  useEffect(() => {
    const fetchMerchantBalance = async () => {
      try {
        const data = {
          _id: jwt_decode(localStorage.getItem("token")).id,
        };
        const response = await axios.post("/shopperrds/getMerchant", data, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        });

        if (response.data.error === 0) {
          setMerchantBalance(response.data.response.balance);
          console.log(response.data.response.balance)
        } else {
          console.error("Error in response:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching merchant balance:", error);
      }
    };
    //let dbitamt = {};
    const calculateTotalAmount = () => {
      const total = selectedOrders.reduce((sum, order) => {
        const orderTotal = order.orderDetails.reduce(
          (orderSum, detail) => orderSum + parseFloat(detail.finalPrice.toFixed(0,2)),
          0
        );
        return sum + orderTotal;
      }, 0);
    
      setTotalAmount(total);
    };
    

    fetchMerchantBalance();
    calculateTotalAmount();
  }, [selectedOrders]);
  //console.log("A", selectedOrders);
  const orderSerialArray = selectedOrders.map((item) => ({
    orderSerial: item.orderSerial,
    systemId: item._id,
    amount: item.orderDetails.map((detail) => ({
      amt : detail.finalPrice,
      sku :  detail.sku,
    })),
  }));
  
  //console.log("iwefiwendl", orderSerialArray);
  

//console.log(orderSerials);
const PaymentUpdate = async () => {
  try {
    const userId = jwt_decode(localStorage.getItem("token")).id;
    const selectedOrdersSerial = orderSerialArray;

    const data = {
      userId,
      orderData: selectedOrdersSerial,
      newBalance: updatedBalance,
      merchantBalance, 
    };

    const response = await axios.post("/shopperrds/updateBalance", data, {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    });

    if (response.data.error === 0) {
      NotificationManager.success("Balance updated successfully!");
      history.push("/merchant/orders");
    } else {
      alert(`Error: ${response.data.message}`);
    }
  } catch (error) {
    console.error("Error updating balance:", error);
    NotificationManager.error("An error occurred while updating balance.");
  }
};


  const handlePayment = () => {
    if (merchantBalance < totalAmount) {

      history.push("/merchant/add-balance");
    } else {
     
      const newBalance = merchantBalance - totalAmount;
      setUpdatedBalance(newBalance);
      setPaymentDone(true);
    }
  };

  return (
    <div className="container mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="shadow-lg rounded p-5" style={{ border: "1px solid #ddd", backgroundColor: "#f8f9fa" }}>
            <h3 className="text-center mb-4" style={{ color: "#333", fontWeight: "600" }}>Pay Information</h3>

            {/* Line after Pay Information */}
            <div style={{ borderBottom: "2px solid #ddd", marginBottom: "20px" }}></div>

            <div className="mb-4">
              <h5 style={{ color: "#555", fontWeight: "500" }}>Number of Orders: {selectedOrders.length}</h5>
            </div>
            <div className="mb-4">
              <h5 style={{ color: "#555", fontWeight: "500" }}>Payables: ₹{totalAmount}</h5>
              <h5 style={{ color: "#555", fontWeight: "500" }}>Merchant Balance: ₹{merchantBalance}</h5>
            </div>

            <div className="text-center mb-4">
              <button
                onClick={handlePayment}
                className="btn btn-warning btn-lg"
                style={{
                  background: "rgb(255, 160, 0)",
                  borderColor: "rgb(255, 160, 0)",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "10px 20px",
                  borderRadius: "25px",
                  transition: "all 0.3s ease",
                  color: "#FFFFFF",
                  minWidth: "150px"
                }}
              >
                Pay Now
              </button>
            </div>

            {/* Show payment summary if payment is done */}
            {paymentDone && (
              <div className="mt-5">
                <h3 className="text-center mb-4" style={{ color: "#333", fontWeight: "600" }}>E-Wallet</h3>

<div className="mb-4">
  <h5 style={{ color: "#555", fontWeight: "500" }}>Number of Orders: {selectedOrders.length}</h5>
</div>
<div className="mb-4">
  <h5 style={{ color: "#555", fontWeight: "500" }}>Payables: ₹{totalAmount}</h5>
  <h5 style={{ color: "#555", fontWeight: "500" }}>Current Balance:  ₹{merchantBalance}</h5>
  <h5 style={{ color: "#555", fontWeight: "500" }}>Balance After Payment:  ₹{updatedBalance}</h5>
</div>
                <div className="text-center">
                  <button
                    onClick={PaymentUpdate}
                    className="btn btn-warning btn-lg"
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "10px 20px",
                      borderRadius: "25px",
                      transition: "all 0.3s ease",
                      color: "#FFFFFF",
                      minWidth: "150px"
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PayNow;
