// import Dashboard from "./views/Dashboard.jsx";
// import AddOnProduct from "./views/AddProduct.jsx"
// import ProductList from "./views/ProductList.jsx"
// import CsvProduct from "./views/CsvProduct.jsx"
// import ShopifyProduct from "./views/ShopifyProduct.jsx"
//import ProductLayout from "./layouts/Products.jsx";
// import SubscribeProduct from "./layouts/SubscribeProduct.jsx";
// // import Orders from "./views/Orders.jsx"
// // import AmazonMarketPlace from "./views/AmazonMarketPlace.jsx";
// import MarketPlace from "./views/MarketPlace.jsx";
// // import FulfilledOrders from "./views/FulfilledOrders.jsx"
// import MerchantProfileSetting from "./views/MerchantProfileSetting.jsx";

import WmsDashboard from "./views/WmsDashboard.jsx";
import InWard from "./views/WmsScreenRoutes/InWard.jsx";
import OffShelf from "./views/WmsScreenRoutes/OffShelf.jsx";
import PrintA4 from "./views/WmsScreenRoutes/PrintA4.jsx";
import Packing from "./views/WmsScreenRoutes/Packing.jsx";
import RetutnRto from "./views/WmsScreenRoutes/RetutnRto.jsx";
import Reprint from "./views/WmsScreenRoutes/Reprint.jsx";
import ReprintIndia from "./views/WmsScreenRoutes/ReprintIndia.jsx";
import Challan from "./views/WmsScreenRoutes/Challan.jsx";
import OffShelfFind from "./views/WmsScreenRoutes/OffShelfFind.jsx";
import IndiaPacking from "./views/WmsScreenRoutes/IndiaPacking.jsx";
import IndiaOffShelf from "./views/WmsScreenRoutes/IndiaOffShelf.jsx";
import AddVendor  from "./views/WmsScreenRoutes/AddVendor.jsx";
import Vendor  from "./views/WmsScreenRoutes/Vendor.jsx";     
import GateEntryList from "./views/WmsScreenRoutes/GateEntrys.jsx";
import AddGateEntry from "./views/WmsScreenRoutes/AddGateEntry.jsx";
import PurchaseOrder  from "./views/WmsScreenRoutes/PurchaseOrder.jsx"
import Incoming from "./views/WmsScreenRoutes/Incoming.jsx";
import UploadRefund from "./views/WmsScreenRoutes/UploadRefund.jsx";
import UploadSafeTClaim from "./views/WmsScreenRoutes/UploadSafeTClaim.jsx";
import Manifest from "./views/WmsScreenRoutes/Manifest.jsx";
import DataManagement from "./views/WmsScreenRoutes/DataManagement.jsx";
import Dustbin from "./views/WmsScreenRoutes/Dustbin.jsx";
import FreshIncomingStock from "./views/WmsScreenRoutes/FreshIncomingStock.jsx";
import StockManagement from "./views/WmsScreenRoutes/StockManagement.jsx";

import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/admin_style.css";
import jwt_decoder from "jwt-decode";
const jwt = require("jwt-simple");
const uuid = require("uuid");
const url = require("url");
const subdomain = "creationist";
const shared_key = "DvYGZ3Rj0hb2IMIhSnbPsoLMTR48JF1t3BXz2YpPc0ePrtZ9";
const token = localStorage.getItem("token");
let decoder = token ? jwt_decoder(token) : "";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: WmsDashboard,
    layout: "/wms",
  },
  {
    path: "/datamanagement",
    name: "Data Management",
    icon: "pe-7s-graph",
    component: DataManagement,
    layout: "/wms",
  },
  {
    path: "/stockmanagement",
    name: "Stock Management",
    icon: "pe-7s-graph",
    component: StockManagement,
    layout: "/wms",
  },
  {
    path: "/inward",
    name: "Incoming Stock",
    icon: "pe-7s-graph",
    component: InWard,
    layout: "/wms",
    subRoutes: [
            {
              path: '/inward',
              name: 'Receiving',
              icon: 'pe-7s-users',
              component: InWard,
              layout: '/wms'
            },
    ]
  },
  {
    path: "/return",
    name: "Return & RTO",
    icon: "pe-7s-graph",
    component: RetutnRto,
    layout: "/wms",
    // subRoutes: [
    //         {
    //           path: '/return',
    //           name: 'Return & RTO',
    //           icon: 'pe-7s-users',
    //           component: RetutnRto,
    //           layout: '/wms'
    //         },
    // ]
  },
  {
    path: '/supplier',
    name: 'Outbound',
    icon: 'pe-7s-users',
    layout: '/wms',
    subRoutes: [
        {
          path: '/offshelf',
          name: 'Offshelf',
          icon: 'pe-7s-users',
          component: OffShelf,
          layout: '/wms'
        },
        {
          path: '/packing',
          name: 'Packing',
          icon: 'pe-7s-users',
          component: Packing,
          layout: '/wms'
        },
        {
          path: '/reprint',
          name: 'Re-Print',
          icon: '<i class="pe-7s-graph2"></i>',
          component: Reprint,
          layout: '/wms'
        },
        {
			path: "/manifest",
			name: "Manifest",
			icon: "pe-7s-graph",
			component: Manifest,
			layout: "/wms",
		},
        {
          path: '/Challan',
          name: 'Challan',
          icon: 'pe-7s-graph2',
          component: Challan,
          layout: '/wms'
        }
      ]
  },
  {
    path: '/indiapacking',
    name: 'India Packing',
    icon: 'pe-7s-users',
    component: IndiaPacking,
    layout: '/wms',
    subRoutes: [
      {
        path: '/indiaoffshelf',
        name: 'Offshelf',
        icon: 'pe-7s-users',
        component: IndiaOffShelf,
        layout: '/wms'
      },
      {
        path: '/indiapacking',
        name: 'Packing',
        icon: 'pe-7s-users',
        component: IndiaPacking,
        layout: '/wms'
      },
      {
          path: '/reprintindia',
          name: 'Re-Print India',
          icon: '<i class="pe-7s-graph2"></i>',
          component: ReprintIndia,
          layout: '/wms'
        }
    ]
    
  },  
  {
    path: "/OffshelfFind",
    name: "Find Off Shelf",
    icon: "pe-7s-look",
    component: OffShelfFind,
    layout: "/wms",
  },  
  {
    path: '/Vendors',
    name: 'Vendors',
    icon: 'pe-7s-add-user',
    component: Vendor,
    layout: '/wms',
},
{
  path: '/AddVendor',
  component: AddVendor,
  layout: '/wms',
},
{
  path: '/PurchaseOrder',
  name: 'Purchase Order',           
  icon: 'pe-7s-users',
  component: PurchaseOrder,
  layout: '/wms'
},
{
  path: '/GateEntryList',
  name: 'GATE ENTRY',
  icon: 'pe-7s-home',
  component: GateEntryList,
  layout: '/wms',
},
{
  path: '/AddGateEntry',
  component: AddGateEntry,
  layout: '/wms',
},
  {
    path: "/Incoming",
    name: "Return Onshelf",
    icon: "pe-7s-graph",     
    component: Incoming,
    layout: "/wms",
  },  
  {
    path: "/UploadRefund",
    name: "Upload Refund",
    icon: "pe-7s-graph",
    component: UploadRefund,
    layout: "/wms",
  },
  {
    path: "/UploadSafeTClaim",
    name: "Upload Safe-T Claim",
    icon: "pe-7s-graph",
    component: UploadSafeTClaim,
    layout: "/wms",
  },
  {
    path: "/FreshIncomingStock",
    name: "Fresh Incoming Stock",
    icon: "pe-7s-graph",
    component: FreshIncomingStock,
    layout: "/wms",
  },
  {
    path: "/dustbin",
    name: "Dustbin",
    icon: "pe-7s-trash",
    component: Dustbin,
    layout: "/wms",
  }
  // {
  //   path: '/print-offshelf/opcode/:opcode',
  //   component: PrintA4,
  //   layout: '/wms',
  // }
];

export default dashboardRoutes;
