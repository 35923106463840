import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import InWardUpload from "../../views/WmsScreenRoutes/InWardUpload";
import Modal from "react-responsive-modal";
// import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from "react-bootstrap";
// import "../../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [showUpload, setShowUpload] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(20);
//   const [selectStore, setSelectStore] = useState("Select Store Name");
  const [storeData, setStoreData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [tempCustomerData, setTempCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [error, setErrors] = useState({});
//   const [defaultStore, setDefaultStore] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [open, setOpen] = useState(false);
  const [moreDesc, setMoreDesc] = useState(false);
  const [msg, setMsg] = useState("");
  const [singleProduct, setSingleProduct] = useState([]);
  const [fulfillstatus, setfulfillstatus] = useState();
  const [percentValue, setpercentValue] = useState();
  const [percentDate, setpercentDate] = useState("");
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);
    // setDefaultStore(domain);
    // setSelectStore(domain);
    getOrders(currentPage, showPerPage, {});
  }, []);
  
  const getOrders = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/inwards?spID=${userId}&page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.results);
          setTotalData(res.data.count);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    // getOrders(e.selected + 1, showPerPage, {});
    getOrders(e.selected + 1, showPerPage, {
      text: searchString,
      });
  };

  const handleChange = (e) => {
    console.log("Came to handle Change", e.target.value);
    document.getElementById("searchOrder").value = "";
    setFromDate("");
    setTodate("");
    // setSelectStore(e.target.value);
    getOrders(currentPage, showPerPage, {});
  };

  const handleSearchInvoice = (e) => {
    setSearchString(e.target.value);
  };

  const handleFromdate = (fromDate) => {
    setFromDate(fromDate);
    setErrors({});
  };

  const handleToDate = (toDate) => {
    setTodate(toDate);
    setErrors({});
  };

  const handleNavigation = () => {
    setShowUpload(!showUpload);
  };
  
  function scanInvoice(){
	console.log(searchString);

	getOrders(1, showPerPage, {
		text: searchString,
	  });
  }


  return (
    <div className="content">
      
      <div className="uploadBtn">
        {showUpload ? (
          <button
            style={{
              background: "rgb(255, 160, 0)",
              borderColor: "rgb(255, 160, 0)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="btn btn-primary btn-fill"
            onClick={handleNavigation}
          >
            INWARD UPLOAD
          </button>
        ) : (
          <button
            style={{
              background: "rgb(255, 160, 0)",
              borderColor: "rgb(255, 160, 0)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="btn btn-primary btn-fill"
            onClick={handleNavigation}
          >
            BACK TO INWARDS
          </button>
        )}
      </div>

      {showUpload ? (
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label>Search</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Text"
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
					            onKeyUp={() => scanInvoice()}
                    />
                  </div>
                </div>
                
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => scanInvoice()}
                  >
                    SEARCH
                  </button>
                </div>
                
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card " style={{ padding: "1em", "overflowX": "auto" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Inwards:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    {/* <th scope="col">Invoice Number</th> */}
                    <th scope="col">CFF Order No.</th>
                    <th scope="col">IStore Order No.</th>
                    <th scope="col">Shopperr Order No.</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Description</th>
                    <th scope="col">Qty</th>
                    <th scope="col" style={{ maxWidth: '100px' }}>Unit Price</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">Total Price(RMB)</th>
                    <th scope="col">Invoice Date</th>
                    {/* <th scope="col">Dispatch Date</th>
                    <th scope="col">Cancel Date</th>
                    <th scope="col">RTO Date</th>
                    <th scope="col">Return Date</th>
                    <th scope="col">Ticketed Date</th> */}
                    {/* <th scope="col">Created Date</th> */}
                    <th scope="col">Status</th>

                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    pageData.map((inw) => {
                      let dd = '';
                      let disFormat = '';
                      if(inw.dispatch_date!==null){
                        dd = new Date(inw.dispatch_date);
                        // disFormat = `${dd.getDate()}-${
                        //   dd.getMonth() + 1
                        // }-${dd.getFullYear()}`;
                        disFormat = dd.toUTCString();
                      }else if(inw.dispatch_date!=''){
                        disFormat = '-';
                      }else{
                        disFormat = '-';
                      }

                      let cd = '';
                      let canFormat = '';
                      if(inw.cancel_date!==null){
                        cd = new Date(inw.cancel_date);
                        // canFormat = `${cd.getDate()}-${
                        //   cd.getMonth() + 1
                        // }-${cd.getFullYear()}`;
                        canFormat = cd.toUTCString();
                      }else if(inw.cancel_date!=''){
                        canFormat = '-';
                      }else{
                        canFormat = '-';
                      }

                      let rd = '';
                      let retFormat = '';
                      if(inw.rto_date!==null){
                        rd = new Date(inw.rto_date);
                        // retFormat = `${rd.getDate()}-${
                        //   rd.getMonth() + 1
                        // }-${rd.getFullYear()}`;
                        retFormat = rd.toUTCString();
                      }else if(inw.rto_date!=''){
                        retFormat = '-';
                      }else{
                        retFormat = '-';
                      }

                      let rtd = '';
                      let rtdFormat = '';
                      if(inw.return_date!==null){
                        rtd = new Date(inw.return_date);
                        // rtdFormat = `${rtd.getDate()}-${
                        //   rtd.getMonth() + 1
                        // }-${rtd.getFullYear()}`;
                        rtdFormat = rtd.toUTCString();
                      }else if(inw.return_date!=''){
                        rtdFormat = '-';
                      }else{
                        rtdFormat = '-';
                      }

                      let td = '';
                      let tdFormat = '';
                      if(inw.ticketed_date!==null){
                        td = new Date(inw.ticketed_date);
                        // tdFormat = `${td.getDate()}-${
                        //   td.getMonth() + 1
                        // }-${td.getFullYear()}`;
                        tdFormat = td.toUTCString();
                      }else if(inw.ticketed_date!=''){
                        tdFormat = '-';
                      }else{
                        tdFormat = '-';
                      }
                      
                      let d = '';
                      if(inw.invoiceDate!=null){
                        let d1 = new Date(inw.invoiceDate);
                        d = d1.toUTCString();
                      }else{
                        d = '-';
                      }
                      
                      // let dd = inw.dispatch_date!='undefined' ? new Date(inw.dispatch_date):'0000:00:00';
                      // let disFormat = `${dd.getDate()}-${
                      //   dd.getMonth() + 1
                      // }-${dd.getFullYear()}`;

                      // let cd = new Date(inw.cancel_date);
                      // let rd = new Date(inw.rto_date);
                      // let rtd = new Date(inw.return_date);
                      // let td = new Date(inw.ticketed_date);
                    //   let cr = new Date(inw.created_date);
                    let order_status = '';  
                    if(inw.order_status == 0){
                      order_status = 'Initialize';
                    }else if(inw.order_status == 1){
                      order_status = 'Dispatch from Warehouse';
                    }else if(inw.order_status == 2){
                      order_status = 'In-Transite';
                    }else if(inw.order_status == 3){
                      order_status = 'Deliever';
                    }else if(inw.order_status == 4){
                      order_status = 'RTO';
                    }else if(inw.order_status == 5){
                      order_status = 'Return';
                    }else if(inw.order_status == 6){
                      order_status = 'QC';
                    }else if(inw.order_status == 7){
                      order_status = 'In Stock';
                    }else if(inw.order_status == 8){
                      order_status = 'Damage';
                    }else if(inw.order_status == 9){
                      order_status = 'Refund';
                    }else if(inw.order_status == 10){
                      order_status = 'Invoiced';
                    }else if(inw.order_status == 11){
                      order_status = 'Cancel Before Dispatch';
                    }else{
                      order_status = '';
                    }


                      return (
                        <tr key={inw._id}>
                          {/* <td scope="row">{inw.inv_number}</td> */}
                          <td>
                            {inw.cff_orderno}
                          </td>
                          <td>{inw.i_orderno}</td>
                          <td>{inw.s_orderno}</td>
                          <td>{inw.sku}</td>
                          <td>{inw.description}</td>
                          <td>{inw.qty}</td>
                          <td>{inw.unitPrice}</td>
                          <td>{inw.totalPrice}</td>
                          <td>{inw.rmbPrice}</td>
                          <td>{d}</td>

                          {/* <td>{disFormat}</td>
                          <td>{canFormat}</td>
                          <td>{retFormat}</td>
                          <td>{rtdFormat}</td>
                          <td>{tdFormat}</td> */}


						            {/* <td>{`${cr.getDate()}-${
                            cr.getMonth() + 1
                          }-${cr.getFullYear()}`}</td>
                          */}
                          <td>
                            {order_status}
                          </td> 
                          
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      ) : (
        <InWardUpload />
      )}
    </div>
  );
}
export default OrderList;
