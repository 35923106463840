import CopyDashboard from './views/ItAdminScreenRoutes/CopyDashboard.jsx'
import InventoryUpdate from './views/ItAdminScreenRoutes/InventoryUpdate.jsx'
import UpdateBalance from './views/ItAdminScreenRoutes/UpdateBalance.jsx'
import jwt_decode from 'jwt-decode';
import axios from 'axios'; 



class adminRoutes{
    dashboardRoutes = [];
    allDashboardRoutes = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: 'pe-7s-graph2',
            component: CopyDashboard,
            layout: '/itadmin',
        },
        {
            path: '/InventoryUpdate',
            name: 'Inventory Update',
            icon: 'pe-7s-edit',
            component: InventoryUpdate,
            layout: '/itadmin',
        },
        {
            path: '/UpdateBalance',
            name: 'Update Balance',
            icon: 'pe-7s-edit',
            component: UpdateBalance,
            layout: '/itadmin',
        },
        
    ]
    constructor(id){
      this._id = id;
    }
    adminRoute() {
        return axios.get("/admin/adminProfile" + this._id).then((res) => {
            
            if(res.data.error == 0){
                let menus = res.data.items.menu;
                if(res.data.items.menu && menus.length){ 
                    menus = menus.map((item)=>'/'+item.Url) 
                    this.dashboardRoutes = this.allDashboardRoutes.filter((item)=>{ 
                        return menus.includes(item.path);
                    }); 
                    return this.dashboardRoutes; 
                } 
            }
            return this.allDashboardRoutes;
        }); 
    }
}

export default adminRoutes;

